import {z} from 'zod';

import {Select as SelectLegacy} from '@/components/shared/validatedForm/legacy/Select';
import {Select} from '@/components/shared/validatedForm/Select';
import {type SelectProps} from '@/components/shared/Select/Select';
import {useTranslations} from '@/hooks/useTranslations';
import {LocationValues} from '@/components/shared/BaseLeadForm/fields/constants';

type Props = {
  selectProps?: Partial<SelectProps>;
  legacyField?: boolean;
};

export const locationsValidation = {
  // eslint-disable-next-line camelcase
  num_brick_and_mortar_locations: z.string().min(1, {
    message: 'forms:lead.locations.error',
  }),
};

// This component is essentially a copy of the LocationSelect in the BaseLeadForm folder with
// a change to the field name/validator to snake case. This matches the naming convention on most forms today.
// This component should be kept around until we can migrate all forms to camel casing through <BaseLeadForm />
export default function LocationsSelect({selectProps, legacyField}: Props) {
  const {t} = useTranslations();

  const options = [
    {
      label: t('forms:lead.locations.options.DEFAULT'),
      value: '',
    },
    {
      label: t('forms:lead.locations.options.ONE'),
      value: LocationValues.ONE,
    },
    {
      label: t('forms:lead.locations.options.TWO_TO_THREE'),
      value: LocationValues.TWO_TO_THREE,
    },
    {
      label: t('forms:lead.locations.options.FOUR_TO_TEN'),
      value: LocationValues.FOUR_TO_TEN,
    },
    {
      label: t('forms:lead.locations.options.ELEVEN_TO_TWENTY'),
      value: LocationValues.ELEVEN_TO_TWENTY,
    },
    {
      label: t('forms:lead.locations.options.TWENTY_ONE_TO_TWO_HUNDRED'),
      value: LocationValues.TWENTY_ONE_TO_TWO_HUNDRED,
    },
    {
      label: t('forms:lead.locations.options.TWO_HUNDRED_PLUS'),
      value: LocationValues.TWO_HUNDRED_PLUS,
    },
    {
      label: t('forms:lead.locations.options.NO_LOCATIONS'),
      value: LocationValues.NO_LOCATIONS,
    },
    {
      label: t('forms:lead.locations.options.OPENING_SOON'),
      value: LocationValues.OPENING_SOON,
    },
  ];

  const translationKey = 'locations';
  const name = 'num_brick_and_mortar_locations';

  return legacyField ? (
    <SelectLegacy
      key={name}
      id={name}
      name={name}
      label={t(`forms:lead.${translationKey}.label`)}
      divider
      {...selectProps}
      options={options}
    />
  ) : (
    <Select
      key={name}
      id={name}
      name={name}
      label={t(`forms:lead.${translationKey}.label`)}
      required
      {...selectProps}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
